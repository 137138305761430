<template>
	<div
		class="full-height pa-10 bg-white"
	>
		<div class="full-height bg-base flex-column justify-center">
			<div class="pa-50 text-center ">
				<v-icon
					class="size-px-48 color-gray"
				>mdi-cloud-off-outline</v-icon>
				<p class="mt-10 size-px-16 color-gray">조회된 내역이 없습니다.</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Empty'
	}
</script>
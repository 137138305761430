<template>
	<div class="full-height">
		<table class="table th-left">
			<col width="160px" />
			<col width="auto" />
			<tbody>
				<tr>
					<th>본사 <label class="radius-10 box pa-0-5" title="PG등록은 본사만 가능합니다">?</label></th>
					<td>
						<select
							v-model="item.seller_id"
							class="input-box width-100"
						>
							<option value="">본사를 선택하세요</option>
							<option
								v-for="(company, index) in items_company"
								:key="'company_' + index"
								:value="company.account_id"
							>{{ company.account_name }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<th>PG사 </th>
					<td>
						<select
							v-model="item.pg_code"
							class="input-box width-100"
						>
							<option value="">PG사를 선택하세요</option>
							<option
								v-for="(code, index) in codes.P004.items"
								:key="code.total_code + '_' + index"
								:value="code.code_value"
							>{{ code.code_name }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<th>PG 결제구분 </th>
					<td>
						<select
							v-model="item.pg_type"
							class="input-box width-100"
						>
							<option value="">PG 결제구분을 선택하세요</option>
							<option
								v-for="(code, index) in codes.P005.items"
								:key="code.total_code + '_' + index"
								:value="code.total_code"
							>{{ code.code_name }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<th>PG 명 <label class="radius-10 box pa-0-5" title="본사에 부여할 PG명을 작성합니다">?</label></th>
					<td>
						<input v-model="item.pg_name" class="input-box " placeholder="PG명" maxlength="25" />
					</td>
				</tr>
				<tr>
					<th>PG 가맹점 명 <label class="radius-10 box pa-0-5" title="PG사에 등록된 가맹점명을 입력합니다">?</label></th>
					<td>
						<input v-model="item.pgMerchName" class="input-box " placeholder="가맹점 명" maxlength="25"/>
					</td>
				</tr>
				<tr>
					<th>PG 가맹점 ID <label class="radius-10 box pa-0-5" title="PG사에서 발급받은 MID를 입력합니다">?</label></th>
					<td>
						<input v-model="item.pgMerchNo" class="input-box " placeholder="가맹점 ID" maxlength="50" />
					</td>
				</tr>
				<tr>
					<th>PG 결제 수수료 <label class="radius-10 box pa-0-5" title="PG사에 등록된 결제 수수료를 입력합니다">?</label></th>
					<td>
						<input v-model="item.pg_fee" class="input-box " placeholder="PG 결제 수수료" :rules="[$rules.demical(item, 'pg_fee', {min: 2, max:2})]"/>
					</td>
				</tr>
				<tr>
					<th>PG KEY <label class="radius-10 box pa-0-5" title="PG사에서 발급받은 결제 KEY(보유시)를 입력합니다">?</label></th>
					<td>
						<input v-model="item.pgKey" class="input-box " placeholder="PG KEY" />
					</td>
				</tr>
				<tr>
					<th>추가 필드1 <label class="radius-10 box pa-0-5" title="PG결제시 필요한 추가 요청정보(PG사 별 상이)를 입력합니다">?</label></th>
					<td>
						<input v-model="item.pg_value1" class="input-box " placeholder="추가 필드" />
					</td>
				</tr>
				<tr>
					<th>콜백 URL <label class="radius-10 box pa-0-5" title="개발자에게 문의하세요">?</label></th>
					<td>
						<input v-model="item.returnUrl" class="input-box " placeholder="콜백 URL" maxlength="250"/>
					</td>
				</tr>
				<tr>
					<th>결제 성공 처리 URL <label class="radius-10 box pa-0-5" title="개발자에게 문의하세요">?</label></th>
					<td>
						<input v-model="item.successUrl" class="input-box " placeholder="결제 성공 처리 URL" maxlength="250"/>
					</td>
				</tr>
				<tr>
					<th>결제 실패 처리 URL <label class="radius-10 box pa-0-5" title="개발자에게 문의하세요">?</label></th>
					<td>
						<input v-model="item.failureUrl" class="input-box " placeholder="결제 실패 처리 URL" maxlength="250"/>
					</td>
				</tr>
				<tr>
					<th>PG API URL - 운영 <label class="radius-10 box pa-0-5" title="개발자에게 문의하세요">?</label></th>
					<td>
						<input v-model="item.pgApiUrl" class="input-box " placeholder="PG API URL - 운영" maxlength="250"/>
					</td>
				</tr>
				<tr>
					<th>PG API URL - 개발 <label class="radius-10 box pa-0-5" title="개발자에게 문의하세요">?</label></th>
					<td>
						<input v-model="item.pgApiUrlDev" class="input-box " placeholder="PG API URL - 개발" maxlength="250"/>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="mt-10">
			<button
				class="bg-identify pa-5-10 mr-10"
				@click="save"
			>저장</button>
			<button
				class="bg-default pa-5-10 mr-10"
				@click="$emit('cancel')"
			>취소</button>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'ManagerPgItem'
		, props: ['Axios', 'user', 'codes', 'rules', 'TOKEN', 'uid']
		, data: function(){
			return {
				program: {
					name: 'PG사 정보 관리'

				}
				, item: {
					pg_code: ''
					, pg_type: ''
					, pg_name: ''
					, pgMerchNo: ''
					, pgMerchName: ''
					, pgApiUrl: ''
					, pgApiUrlDev: ''
					, returnUrl: ''
					, failureUrl: ''
					, successUrl: ''
					, seller_id: ''
				}
				, items_company: []
			}
		}
		, computed: {

		}
		, methods: {
			save: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'admin/postPgItem'
						,data: this.item
					})

					if(result.success){
						this.$emit('success')
						this.$bus.$emit('notify', { type: 'success', message: result.message })
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'management/getPgItem'
						,data: {
							uid: this.uid
						}
					})

					if(result.success){
						this.item = result.data
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getCompanyList: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'admin/getCompanyList'
						,data: this.search
					})

					if(result.success){
						this.items_company = result.data.result
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, async created() {
			await this.getCompanyList()
			if(this.uid){
				await this.getData()
			}
		}
	}
</script>